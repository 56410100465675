import {$QSA,$QS,$I} from "app/dom-util";
export default function () {
  $QSA(".date-time-convert-time-zone-change").forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      $QSA("[data-fecha-utc], .date-time-convert-time-zone-change").forEach(el => {
        el.dataset.localTime = (el.dataset.localTime === undefined ? false : !(el.dataset.localTime === 'true'));
        el.textContent = (el.dataset.localTime === 'true' ? el.dataset.localFormat : el.dataset.utcFormat);
      });
    });
  });
  $QSA("[data-fecha-utc]").forEach(el => {
    const dateUtc = new Date(el.dataset.fechaUtc);
    const dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};
    const timeOptions = {hour: '2-digit', minute: '2-digit'};
    let options = dateOptions;
    if (el.classList.contains("date-time-convert-time-format")) {
      options = timeOptions;
    }
    el.dataset.utcFormat = el.textContent;
    el.dataset.localFormat = new Intl.DateTimeFormat('es-ES', options).format(dateUtc);
    el.textContent = el.dataset.localFormat;
  });
  $QSA("input.date-time-convert-from-utc").forEach(el=>{
    const dateTime=new Date(el.value+"Z");
    const dateString =  dateTime.getFullYear() + "-" + String(dateTime.getMonth()+1).padStart(2,"0") + "-" + String(dateTime.getDate()).padStart(2,"0") + "T" +
String(dateTime.getHours()).padStart(2,"0") + ":" + String(dateTime.getMinutes()).padStart(2,"0") + ":" + String(dateTime.getSeconds()).padStart(2,"0");
    el.value=dateString;
  });
  
  $QSA("form.date-time-convert-from-utc").forEach(el => {
    el.addEventListener("submit", e => {
      e.preventDefault();
      $QSA("input.date-time-convert-from-utc").forEach(el=>{
        const dateTime=new Date(el.value);
        el.value=dateTime.toISOString().slice(0,-5);        
      });
      el.submit();
    })
    
  });
  $QSA(".date-time-convert-time-zone-change").forEach(el => {
    el.dataset.utcFormat = el.textContent;
    el.dataset.localFormat = "Hora local";
    el.textContent = el.dataset.localFormat;
  });
  const isDate = (date) => {
    let dateObj = new Date(date);
    return (dateObj !== "Invalid Date") && !isNaN(dateObj);
  }
  window.dateFormat=function(value){
    if (!isDate(value)) {
      return value;
    }
    const dateTime=new Date(value);
    const dateString =  String(dateTime.getDate()).padStart(2,"0") + "/" + String(dateTime.getMonth()+1).padStart(2,"0") + "/" + dateTime.getFullYear() +  " " +
String(dateTime.getHours()).padStart(2,"0") + ":" + String(dateTime.getMinutes()).padStart(2,"0") + ":" + String(dateTime.getSeconds()).padStart(2,"0");
    return dateString;
  };
}

