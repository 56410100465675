'use strict';
import {crearElemento, $I} from "app/dom-util";

export function mostrarError(mensaje) {
  mostrarAlerta(mensaje, "alert-danger");
}
export function mostrarMensaje(mensaje) {
  mostrarAlerta(mensaje, "alert-success");
}
function alertaBaseHtml(mensaje, clase) {
  return '<div class="alert alert-dismissible fade show ' + clase + '" role="alert">'
          + '<span class="mensaje">' + mensaje + '</span>'
          + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>'
          + '</div>';
}
function mostrarAlerta(mensaje, clase) {
  [...document.querySelectorAll('[data-alerta="clone"]')].forEach(el => el.parentNode.removeChild(el));
  const alertaBase = $I("alerta-base");
  let alerta;
  if (alertaBase) {
    alerta = alertaBase.cloneNode(true);
    alerta.removeAttribute("id");
    alerta.classList.add(clase);
    alerta.dataset.alerta = "clone";
    alerta.classList.remove("d-none");
    alerta.getElementsByClassName("mensaje")[0].innerHTML = mensaje;
    alertaBase.parentNode.prepend(alerta);
  } else {
    alerta = document.createElement('div');
    document.body.prepend(alerta);
    alerta.outerHTML = alertaBaseHtml(mensaje, clase);
  }
  alerta.dataset.alerta = "clone";
  scroll({
    top: 0,
    behavior: 'smooth'
  });
}
