'use strict';
export const $I = id => document.getElementById(id);

export function crearElemento(tipo,attributes={}) {
  const e = document.createElement(tipo);
  Object.entries(attributes).forEach(([name, value])=>e.setAttribute(name,value));
  return e;
}

export const $C = clase => document.getElementsByClassName(clase);

export const $QSA = selector => document.querySelectorAll(selector);

export const $QS = selector => document.querySelector(selector);
