'use strict';
import {$QSA} from "app/dom-util";
export default function toogle() {
  $QSA(".toggle-password").forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      const padre = el.closest('.input-group');
      var input = padre.querySelector('input.form-control');
      input.type = (input.type === "password" ? "text" : "password");
      el.classList.toggle('fa-eye-slash');
    })
  });
};