'use strict';

//import "app/template/js/jquery.min.js"
import "app/template/css/all.min.css"
import "app/template/css/sb-admin-2.min.css"
import "app/template/css/cryptomonedas.css"
//import easing from "jqueryEasing";
import "app/template/js/jquery.easing.js"
import "app/template/js/sb-admin-2.js"

//import "app/template/js/bootstrap.bundle.min.js"


//  <link href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet">

export default () => {
  
};