'use strict';
export const post = (url, object) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(object)
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error("Error en la petición (" + response.status + ")");
    }
    const json=await response.json();
    return {"ok":response.ok,"json":json};//lanza un error
  });
};
export const get = (url) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error("Error en la petición (" + response.status + ")");
    }
    const json=await response.json();
    return {"ok":response.ok,"json":json};//lanza un error
  });
};

