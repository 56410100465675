'use strict';
import {$QSA} from "app/dom-util";

export default function confirmaciones() {
  const confirma = (e,texto) => {    
    if (!confirm(texto)) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };
  $QSA(":not(form)[data-confirm]").forEach(el => {
    el.addEventListener("click", e=>confirma(e,el.dataset.confirm));
  });
  $QSA("form[data-confirm]").forEach(el => {
    el.addEventListener("submit", e=>confirma(e,el.dataset.confirm));
  });
  window.actionEvents = {
    'click [data-confirm]' : (e, value, row, index) => confirma(e,e.target.dataset.confirm)
  }
};