'use strict';
import {Collapse} from 'bootstrap';
import {$QSA, $QS} from "app/dom-util";

export default async function perfil() {
  const radiosEsEmpresa = $QSA("input[name='esEmpresa']");
  const elementosParticularVariables = $QSA("div.tipo-particular");
  const elementosEmpresaVariables = $QSA("div.tipo-empresa");
  const inputsElementosParticularVariables = $QSA("div.tipo-particular input");
  const inputsElementosEmpresaVariables = $QSA("div.tipo-empresa input");
  let eventChange = new MouseEvent('change', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  let triggerChange = () => {
    $QS("input[name='esEmpresa']:checked").dispatchEvent(eventChange);
  };

  let collapsesEmpresa = [];
  let collapsesParticular = [];
  if (radiosEsEmpresa.length) {
    inputsElementosParticularVariables.forEach((el) => {
      if (el.required) {
        el.dataset.required = 1;
        el.required = false;
        el.disabled = true;
      }
    });
    elementosParticularVariables.forEach((el) => {
      collapsesParticular.push({elements: el.querySelectorAll("input"), collapse: new Collapse(el, {
          toggle: false
        })});
      el.addEventListener('shown.bs.collapse', () => triggerChange());
      el.addEventListener('hidden.bs.collapse', () => triggerChange());
    });
    inputsElementosEmpresaVariables.forEach((el) => {
      if (el.required) {
        el.dataset.required = 1;
        el.required = false;
        el.disabled = true;
      }
    });
    elementosEmpresaVariables.forEach((el) => {
      collapsesEmpresa.push({elements: el.querySelectorAll("input"), collapse: new Collapse(el, {
          toggle: false
        })});
      el.addEventListener('shown.bs.collapse', () => triggerChange());
      el.addEventListener('hidden.bs.collapse', () => triggerChange());
    });


    radiosEsEmpresa.forEach((radio) => {
      if (radio.disabled) {
        radio.disabled = false;
        radio.dataset.disabled = 1;
      }
      radio.addEventListener("change", (e) => {
        e.preventDefault();
        radiosEsEmpresa.forEach((radio) => {
          if (radio.dataset.disabled === "1") {
            radio.disabled = true;
            delete radio.dataset.disabled;
          }
        });
        if (radio.id === "tipo-empresa") {
          collapsesParticular.forEach(collapse => {
            collapse.collapse.hide();
            collapse.elements.forEach((el) => {
              if (el.dataset.required === "1") {
                el.required = false;
                el.disabled = true;
              }
            });
          });
          collapsesEmpresa.forEach(collapse => {
            collapse.collapse.show();
            collapse.elements.forEach((el) => {
              if (el.dataset.required === "1") {
                el.required = true;
                el.disabled = false;
              }
            });

          });
        } else {
          collapsesEmpresa.forEach(collapse => {
            collapse.collapse.hide();
            collapse.elements.forEach((el) => {
              if (el.dataset.required === "1") {
                el.required = false;
                el.disabled = true;
              }
            });
          });
          collapsesParticular.forEach(collapse => {
            collapse.collapse.show();
            collapse.elements.forEach((el) => {
              if (el.dataset.required === "1") {
                el.required = true;
                el.disabled = false;
              }
            });

          });
        }
      });
    });
    triggerChange();
  }
};