import SlimSelect from 'slim-select'
//La versión 1.27.1 tiene un bug y se debe importar así porque no exporta los css para webpack 5
import '../node_modules/slim-select/dist/slimselect.css'
import {$QSA} from "app/dom-util";
import 'gasparesganga-jquery-loading-overlay/dist/loadingoverlay.js';

const asyncSelect = (slimSelect, options) => {
  $.LoadingOverlay("show");
  options.forEach(l => {
    setTimeout(() => l.dispatchEvent(new Event("click", {
        bubbles: true
      })));
  });
  let interval = null;
  let selected = [];
  interval = setInterval(() => {
    let current = slimSelect.selected();
    if (selected.length === current.length) {
      $.LoadingOverlay("hide");
      clearInterval(interval);
    }
    selected = current;
  }, 2000);
};

export default async function slimSelect() {
  $QSA(".search-select").forEach(el => {
    let options = {
      select: el,
      searchPlaceholder: 'Buscar'
    };
    if (el.dataset.hideSelectedOption) {
      options.hideSelectedOption = (el.dataset.hideSelectedOption === "true" ? true : false);
    }
    if (el.dataset.placeholder) {
      options.placeholder = el.dataset.placeholder;
    }
    const slimSelect = new SlimSelect(options);
    if (el.dataset.selectByGroup) {
      $QSA("div.ss-optgroup-label").forEach(group => {
        if (group.textContent.indexOf("Todos") >= 0) {
          group.addEventListener("click", e => {
            e.preventDefault();
            asyncSelect(slimSelect, $QSA("div." + el.dataset.ssid + " div.ss-option"));
          });
        } else {
          group.addEventListener("click", e => {
            e.preventDefault();
            asyncSelect(slimSelect, group.parentNode.querySelectorAll(" div.ss-option"));
          });
        }
        group.classList.add("ss-optgroup-label-selectable");
      })
    }
  });
}