'use strict';
export default function sortables() {
  window.numberSorter = (a, b) => {
    const aa = +((a + '').replace(/[^\d.-]/g, ''))
    const bb = +((b + '').replace(/[^\d.-]/g, ''))
    return aa - bb
  };
  window.textSorter = (a, b) => a.localeCompare(b);
  window.dateSorter = (a, b) => {
    const z = x => x > 9 ? x : '0' + x
    const parse = str => {
      const [day, month, year, hour, min, sec, soc] = str.split(/\/|\s|\:/)
      const date = new Date(year, month - 1, day, hour, min)
      const y = z(date.getFullYear())
      const m = z(date.getMonth() + 1)
      const d = z(date.getDate())
      const h = z(hour ?? 0)
      const mn = z(min ?? 0)
      const s = z(sec ?? 0)
      return `${y}-${m}-${d}T${h}:${mn}:${s}`
    }
    //const aa = parse(a);
    //const bb = parse(b);
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
  window.hexSorter = (a, b) => {
    if (a === null || b === null) {
      return a === b ? 0 : a === null ? -1 : 1;
    }
    return parseInt(a.substring(2), 16) - parseInt(b.substring(2), 16);
  }
};

