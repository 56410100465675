'use strict';
export default class Exception extends Error {
  constructor(...params) {
    super(...params);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack || '';
    }
  }
}